import React, {useState} from "react"
import type { HeadFC, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout"
import PageBanner from "../components/pagebanner"
import Seo from "../components/seo"

const ThankYouPage: React.FC<PageProps> = () => {
  return (
    <Layout>
      <PageBanner><span className="colored">Thank You</span> For Your Enquiry</PageBanner>
      
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <p>We&apos;ll get back to you shortly. If you&apos;d like to set up a video call you can do that now below.</p>
            
            <a href="https://calendly.com/plextechweb/" className="btn btn-primary with-arrow btn-sm">Set Up Video Call <FaArrowRight /></a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYouPage

const seo = {
  metaDesc: "PlexTech Marketing - Thank You For Enquiring",
}

export const Head = () => <Seo title="PlexTech Marketing" type="website" url="/thank-you/" seo={seo} />
